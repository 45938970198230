import React, { Fragment } from 'react';

export default () => (
    <Fragment>
        <div className="loadingio-spinner-spinner-mobvtfhpq"><div className="ldio-b56w8yib6qp">

            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    </Fragment>
);
