export default {
    'aboutDescriptionP1': {
        en: 'Musica para Respirar (Music to Breathe) 24/7 is a project of the Bolivian Chamber Music Society (La Sociedad) that brings together professional musicians from across Latin America to offer online concerts to people impacted by COVID-19 everywhere. The concerts are free and provided via video calls by teams of musicians for 24 hours-a-day over the course of a week.',
        es: 'Música para Respirar 24/7 es un proyecto de la Sociedad Boliviana de Música de Cámara que reúne músicos y músicas profesionales de toda América Latina para ofrecer conciertos virtuales, con el propósito de dar un respiro musical a personas impactadas directa o indirectamente por la Covid-19 en todo el mundo. Los mini-conciertos son totalmente gratuitos y se realizan a través de videollamadas por músicos y músicas que están disponibles 24 horas al día por una semana.',
        pt: 'Música para Respirar 24/7 é um projeto da Sociedade Boliviana de Música de Câmara que une músicos e músicas profissionais de toda a América Latina para oferecer concertos virtuais para pessoas impactadas pelo COVID-19 em todo o mundo. Os mini-concertos são totalmente gratuitos e realizados através de videochamadas por equipes de músicos e músicas disponíveis 24 horas por dia ao longo de uma semana.'
    },
    'aboutDescriptionP2': {
        en: 'Música para Respirar 24/7 was created to pay tribute and offer a brief respite to overstretched healthcare workers and anybody impacted by the COVID-19 pandemic. Besides helping to reduce feelings of isolation and providing a space for tranquility for the listener, Música para Respirar 24/7 also aims to democratize classical music by using social media to reach communities with limited access to the arts.',
        es: 'Lanzada en Agosto del 2020 cómo una respuesta artística a la crisis sanitaria global, la idea de tener músicos y músicas disponibles 24 horas al día fue conceptualizada como un tributo al personal del área de la salud y sus turnos extenuantes, así también como una declaración sobre la importancia de la música en tiempos de crisis. La intención de cada concierto es crear un espacio de tranquilidad y bienestar para cada oyente. ',
        pt: 'Música para Respirar 24/7 foi criada para homenagear e oferecer um breve respiro aos trabalhadores da saúde sobrecarregados e à qualquer pessoa impactada pela pandemia de COVID-19. Além de reduzir a sensação de isolamento e proporcionar um espaço de tranquilidade ao ouvinte, Música para Respirar 24/7 também visa democratizar a música clássica por meio das mídias sociais, atingindo comunidades com acesso limitado às artes.'
        },
    'aboutDescriptionP3': {
        en: 'Since it was launched in August of 2020, Musica para Respirar 24/7 has brought together more than 80 musicians from across Latin America, Europe and the US and performed to more than 12,000 people in 50 countries around the world.  Healthcare workers, vulnerable children in orphanages and elderly people in retirement homes have been among the recipients of concerts. Música para Respirar 24/7 has also provided respite to hundreds of survivors of Covid-19 and their friends and families around the world.',
        es: 'Hasta la fecha, estos conciertos han sido presentados por 80 músicos profesionales y han alcanzado a más de 12,000 personas en 50 países. Rápidamente, Música para Respirar 24/7 se ha convertido en una herramienta de democratización de la música clásica, llegando a poblaciones que inclusive antes de la pandemia no tenían acceso a esta expresión artística.',
        pt: 'Desde seu lançamento em Agosto de 2020, Música para Respirar 24/7 reuniu mais de 80 músicos e músicas de toda a America Latina, Europa e Estados Unidos e se apresentou para mais de 12.000 pessoas em 50 países ao redor do mundo. Profissionais da saúde, crianças em situação de vulnerabilidade, idosos em asilos estão entre os destinatários dos concertos. Música para Respirar 24/7 também realizou concertos para centenas de sobreviventes do COVID-19 e suas famílias ao redor do mundo.'
    },
    'aboutLaSociedadP1': {
        en: 'The Bolivian Chamber Music Society (La Sociedad) was created in 2014 by musicians Camila Barrientos, Bruno Lourensetto and Sergio Escalera with the mission of *making classical music available to all by creating, producing and promoting exceptional artistic content in Bolivia, Latin America and the world*.',
        es: 'La Sociedad Boliviana de Música de Cámara (La Sociedad) fue creada en 2014 por los músicos Camila Barrientos, Bruno Lourensetto y Sergio Escalera con la misión de *democratizar el acceso a la música clásica, creando, produciendo y promoviendo experiencias de excepcional contenido artístico en Bolivia, Latinoamérica y el mundo*.',
        pt: 'A Sociedade Boliviana de Música de Câmara (La Sociedad) foi criada em 2014 por Camila Barrientos, Bruno Lourensetto e Sergio Escalera com a missão de *democratizar o acesso à música clássica, criando, produzindo e promovendo experiências de excepcional contudo artístico na Bolivia, América Latina e no mundo*.'
        },
    'aboutLaSociedadP2': {
        en: "La Sociedad has organized annual festivals in Bolivia where it premiered works such as the Quartet for the End of Time by Oliver Messiaen, Soldier's Tale by Igor Stravinsky and In C by Terry Riley. Deeply committed to the future of classical music in the region, La Sociedad launched the program Promesas with the aim of providing professional opportunities to young Bolivian musicians in 2015.",
        es: 'En sus festivales anuales en Bolivia, presentó los estrenos bolivianos de obras como El Cuarteto para el Fin de los Tiempos de Oliver Messiaen, La historia del Soldado de Igor Stravinsky e In C de Terry Riley, entre otras. Comprometidos con el futuro de la música clásica en Bolivia crearon el proyecto “Promesas” para brindar oportunidades profesionales a jóvenes músicos bolivianos.',
        pt: 'Em seus festivais anuais, La Sociedad apresentou estréias bolivianas de obras como O Quarteto para o Fim dos Tempos de Olivier Messiaen, A História do Soldado de Igor Stravinsky e In C de Terry Riley, entre outras obras. Comprometidos com o futuro da música clássica na Bolívia, La Sociedad lançou em 2015 o programa Promesas com o objetivo de oferecer oportunidades profissionais para jovens músicos bolivianos.'
        },
    'theTeam': {
        en: 'La Sociedad is made up of 10 musicians, six of whom were former Promesas recipients.',
        es: 'Actualmente está conformada por 10 músicos y músicas, 6 de las cuales fueron parte del proyecto Promesas.',
        pt: 'La Sociedad é formada por 10 músicos e músicas, seis dos quais foram parte do projeto Promesas.'
        },

    // Instruments
    'acordeon': {en: 'Acordeon', es: 'Acordeon', pt: 'Acordeon'},
    'baroque violin': {en: 'Baroque Violin', es: 'Violin Barroco', pt: 'Violino Barroco'},
    'bass': {en: 'Bass', es: 'Contrabajo', pt: 'Contrabaixo'},
    'bassoon': {en: 'Bassoon', es: 'Fagot', pt: 'Fagote'},
    'cello': {en: 'Cello', es: 'Cello', pt: 'Cello'},
    'charango': {en: 'Charango', es: 'Charango', pt: 'Charango'},
    'clarinet': {en: 'Clarinet', es: 'Clarinete', pt: 'Clarinete'},
    'flute': {en: 'Flute', es: 'Flauta', pt: 'Flauta'},
    'guitar': {en: 'Guitar', es: 'Guitarra', pt: 'Violão'},
    'harp': {en: 'Harp', es: 'Arpa', pt: 'Arpa'},
    'multi-instrumentalist': {en: 'Multi-Instrumentalist', es: 'Multi-Instrumentista', pt: 'Multi-instrumentalist'},
    'music therapist': {en: 'Music Therapist', es: 'Musicoterapeuta', pt: 'Musicoterapeuta'},
    'oboe': {en: 'Oboe', es: 'Oboe', pt: 'Oboé'},
    'percusion': {en: 'Percussion', es: 'Percusión', pt: 'Percussão'},
    'piano': {en: 'Piano', es: 'Piano', pt: 'Piano'},
    'saxophone': {en: 'Saxophone', es: 'Saxofón', pt: 'Saxofone'},
    'singer songwriter': {en: 'Singer Songwriter', es: 'Cantautor', pt: 'Cantor e compositor'},
    'sonotherapist': {en: 'Sonotherapist', es: 'Sonoterapeuta', pt: 'Sonoterapeuta'},
    'soprano': {en: 'Soprano', es: 'Soprano', pt: 'Soprano'},
    'trumpet': {en: 'Trumpet', es: 'Trompeta', pt: 'Trompete'},
    'viola': {en: 'Viola', es: 'Viola', pt: 'Viola'},
    'violin': {en: 'Violin', es: 'Violin', pt: 'Violino'},
    'voice': {en: 'Voice', es: 'Voz', pt: 'Voz'},

    // Countries
    'argentina': {en: 'Argentina', es: 'Argentina', pt: 'Argentina'},
    'bolivia': {en: 'Bolivia', es: 'Bolivia', pt: 'Bolívia'},
    'brazil': {en: 'Brazil', es: 'Brasil', pt: 'Brasil'},
    'chile': {en: 'Chile', es: 'Chile', pt: 'Chile'},
    'colombia': {en: 'Colombia', es: 'Colombia', pt: 'Colombia'},
    'ecuador': {en: 'Ecuador', es: 'Ecuador', pt: 'Ecuador'},
    'guatemala': {en: 'Guatemala', es: 'Guatemala', pt: 'Guatemala'},
    'hungary': {en: 'Hungary ', es: 'Hungria', pt: 'Hungria'},
    'mexico': {en: 'Mexico', es: 'Mexico', pt: 'México'},
    'panama': {en: 'Panama', es: 'Panamá', pt: 'Panamá'},
    'paraguay': {en: 'Paraguay', es: 'Paraguay', pt: 'Paraguay'},
    'peru': {en: 'Peru', es: 'Perú', pt: 'Perú'},
    'spain': {en: 'Spain', es: 'España ', pt: 'Espanha'},
    'united states of america': {en: 'United States of America', es: 'USA', pt: 'Estados Unidos'},
    'uruguay': {en: 'Uruguay', es: 'Uruguay', pt: 'Uruguay'},

    // Titles
    'co-founderF': {pt: 'CO-FUNDADORA', en: 'CO-FOUNDER', es: 'CO-FUNDADORA'},
    'co-founderM': {pt: 'CO-FUNDADOR', en: 'CO-FOUNDER', es: 'CO-FUNDADOR'},
    'finance director': {pt: 'DIRETORA FINANCEIRA', en: 'FINANCE DIRECTOR', es: 'DIRECTORA FINANCIERA'},
    'press and communications': {pt: 'COORDENADORA DE IMPRENSA', en: 'PRESS AND COMMUNICATIONS', es: 'COORDINADORA PRENSA'},
    'artistic production': {pt: 'PRODUTORA ARTÍSTICA', en: 'ARTISTIC PRODUCTION', es: 'PRODUCCIÓN ARTÍSTICA'},
    'support': {pt: 'APOIO', en: 'SUPPORT', es: 'APOYO'},
    'media support': {pt: 'APOIO MEDIA', en: 'MEDIA SUPPORT', es: 'APOYO MEDIA'},
    'inst. coordinator': {pt: 'COORDENADORA INST.', en: 'INST. COORDINATOR', es: 'COORDINADORA INSTI.'},
    'mpr coordinator': {pt: 'COORDENADORA MPR', en: 'MPR COORDINATOR', es: 'COORDINACIÓN MPR'},
    'artistic production': {pt: 'PRODUTORA ARTÍSTICA', en: 'ARTISTIC PRODUCTION', es: 'PRODUCCIÓN ARTÍSTICA'},

    // Bios
    'camilabio': {
        en: "Born in Cochabamba, Camila is the Principal Clarinet of the São Paulo Municipal Symphony Orchestra. She has played with the New York Philharmonic, the Miami Symphony, the Orchestra of the Americas, among others. She received her master's and bachelor's degrees from the Manhattan School of Music in New York. Camila has performed in the most prominent halls around the world such as Carnegie Hall, Berlin Konzerhaus, as well as in unusual spaces such as St. Peter's Basilica in the Vatican, LPR and the Island of the Sun in Lake Titikaka. A passionate chamber musician, Camila was a member of the woodwind quintet The City of Tomorrow and is the artistic director of the Bolivian Chamber Music Society. Camila lives in São Paulo with her partner and their dog Osita. She's a Buffet Crampon Artist.",
        es: 'Nacida en Cochabamba, Camila es Clarinete Solista de la Orquesta Sinfónica Municipal de São Paulo. Ha tocando con la Filarmónica de Nueva York, la Sinfónica de Miami, la Orquesta de las Americas, entre otras. Recibió su maestría y licenciatura de Manhattan School of Music en Nueva York. Se presentó en los más destacados palcos alrededor del mundo como ser Carnegie Hall, Berlin Konzerhaus, así como en espacios inusitados como la Basílica de San Pedro en el Vaticano, LPR y la Isla del Sol en el Lago Titikaka. También goza de una intensa actividad de música de cámara, fue integrante del premiado quinteto de maderas The City of Tomorrow y es directora artística de la Sociedad Boliviana de Música de Cámara. Camila vive en São Paulo con su compañero y su perrita Osita.',
        pt: 'Nascida em Cochabamba, Camila é Clarineta Solista da Orquestra Sinfônica Municipal de São Paulo. Tocou com a Filarmônica de Nova Iorque, Sinfônica de Miami, Orquestra das Américas, entre outras. Recebeu mestrado e bacharelado da Manhattan School of Music em Nova Iorque. Se apresentou nos mais destacados palcos ao redor do mundo como o Carnegie Hall e Berlin Konzerthaus, assim como em espaços inusitados como a Basílica de São Pedro no Vaticano, LPR e a Ilha do Sol no Lago Titikaka. Também desfruta de intensa atividade de música de câmara, foi integrante do premiado quinteto de madeiras The City of Tomorrow e é diretora artística da Sociedad Boliviana de Música de Cámara. Camila vive em São Paulo com seu companheiro e sua cachorrinha Osita.'},
    'brunobio': {
        en: "Brazilian, hailed as “impressively confident” by the New York Times and “simply stupendous” by the English Arts Desk, Bruno is the principal trumpet of the Bachiana Philharmonic Orchestra, member of the Portland Baroque Orchestra and co-founder of the Bolivian Society of Chamber Music. Bruno was the first trumpet of the Miami Symphony, Querétaro Philharmonic and Guanajuato Symphony, as well as guest of OSESP,  Municipal Symphony Orchestra of São Paulo, Mineria Symphony and Louisiana Philharmonic. He was a teaching assistant at the University of Southern Mississippi, substitute for the University of São Paulo and is faculty at the Festival Musica nas Montanhas. He received performance diplomas from the Mannes College NYC and Indiana University, master's degree from the University of Southern Mississippi, and a bachelor's degree from the University of São Paulo. He lives in São Paulo with his partner and his little dog Osita.",
        es: 'Brasileño, aclamado como “impresionantemente seguro” por el New York Times y “simplemente estupendo” por el periódico inglés Arts Desk, Bruno es trompetista principal de la Orquestra Filarmonica Bachiana, invitado de la Osesp - Orquestra Sinfônica do Estado de São Paulo, miembro de la Portland Baroque Orchestra y co-fundador de La Sociedad Boliviana de Música de Cámara. Recibió diplomas de performance de Mannes College NY, Indiana University, maestria de University of Southern Mississippi y licenciatura de la Universidad de São Paulo. Es profesor en el Festival Internacional de Bauru y el Festival Música nas Montanhas y actualmente reside en São Paulo.',
        pt: 'Brasileiro, aclamado como “impressionantemente seguro” pelo New York Times y “simplemente estupendo” pelo jornal inglês Arts Desk, Bruno é trompetista principal da Orquestra Filarmônica Bachiana, membro da Orquestra Barroca de Portland e co-fundador da Sociedade Boliviana de Música de Câmara. Bruno foi primeiro trompete da Orquestra Sinfônica de Miami, Querétaro e Guanajuato, além de convidado da OSESP, Sinfônica do Theatro Municipal de São Paulo, Sinfônica Mineria e Filarmônica da Louisiana. Foi professor assistente da Universidade do Sul do Mississippi, substituto da Universidade de São Paulo e é professor do Festival Música nas Montanhas. Recebeu diplomas de performance da Mannes College NYC e Indiana University, mestrado da University of Southern Mississippi e bacharelado da Universidade de São Paulo. Vive em São Paulo com sua companheira e sua cachorrinha Osita.'},
    'vaniabio': {
        en: 'Born in Cochabamba, she studied at Instituto Eduardo Laredo, the Interlochen Arts Academy (USA) and at Michigan State University. She is also a graduate of the Orchestra of the Americas Global Leaders Program. Throughout her career, Vania Andrea has been a member of different orchestras and participated in orchestral tours throughout Bolivia, Latin America, the United States, Canada and Europe. She currently resides in La Paz city and is part of the Bolivia Clásica Foundation and the National Symphony Orchestra.',
        es: 'Cochabambina, realizó sus estudios en el Instituto Eduardo Laredo, la academia Interlochen Arts Academy (E.E.U.U.) y en la Universidad del Estado de Michigan. También es graduada del  Programa de  Líderes Globales de la Orquesta de las Américas. A través de su carrera, Vania Andrea ha sido integrante de diferentes orquestas y participado en giras orquestales a través de Bolivia, Latinoamérica, Estados Unidos, Canadá y Europa.  Actualmente reside en la ciudad de La Paz y es parte de la Fundación Bolivia Clásica y de la Orquesta Sinfónica Nacional.',
        pt: 'Cochabambina, realizou seus estudos no Instituto Eduardo Laredo na Bolívia, na academia Interlochen Arts Academy e na Universidade do Estado de Michigan, nos Estados Unidos. Também é graduada do Programa de Líderes Globais da Orquestra das Américas. Em sua carreira, Vania Andrea tem sido integrante de diferentes orquestras e participando de turnês pela Bolívia, América Latina, Estados Unidos, Canadá e Europa. Atualmente reside na cidade de La Paz e é parte da Fundação Bolívia Clássica e da Orquestra Sinfônica Nacional.'},
    'anahibio': {
        en: 'Born in Cochabamba, she began playing the cello at the age of 10 at Instituto Eduardo Laredo. She continued her music studies in La Paz city and later,  in the United Kingdom. Anahi completed a Music BA at Newcastle University and a Master of Music-Creative Musician at Leed College of Music. She had the opportunity to participate in musical projects in Bolivia, the United Kingdom and Ecuador, and she has a great interest in cultural musicology. She currently lives in Quito, where she works as a cellist in various projects.',
        es: 'Nacida en Cochabamba, empezó a tocar el cello a los 10 años en el Instituto Eduardo Laredo. Posteriormente continuó sus estudios en la ciudad de La Paz y en el Reino Unido. Realizó un Music BA en Newcastle University y un Master of Music - Creative Musician en Leed College of Music. Tuvo la oportunidad de participar en proyectos musicales en Bolivia, el Reino Unido y Ecuador, y tiene un gran interés en la musicología cultural. Actualmente vive en Quito, donde se desempeña como cellista en diversos proyectos.',
        pt: 'Nascida em Cochabamba, começou a tocar cello aos 10 anos no Instituto Eduardo Laredo. Posteriormente iniciou seus estudos na cidade de La Paz e no Reino Unido. Realizou seu bacharelado em Música na Newcastle University e mestrado em Criatividade Musical na Leed College of Music. Teve a oportunidade de participar de projetos musicais tanto na Bolívia quanto no Reino Unido e tem um enorme interesse na musicologia cultural. Atualmente vive em Quito, onde se desempenha como cellista em diversos projetos.'
    },
    'claudiabio': {
        en: "Bolivian, 24 years old. Apart from being a member of the Bolivian Chamber Music Society, she is part of the São Paulo State Symphony Orchestra (Osesp) guided by Pedro Gadelha since 2018 and also of the PianoSofia chamber music ensemble together with Cristian Budu. Winner of numerous competitions and performed as a soloist on one occasion with the Jena Philharmonic Orchestra (Germany). She currently resides in Brazil and studies a Bachelor of contrabass ass at the São Paulo State University (UNESP).",
        es: 'Boliviana, 24 años. Además de ser parte del equipo de la Sociedad Boliviana de Música de Cámara, desde 2018 es Academista de la Orquesta Sinfónica del Estado de São Paulo (Osesp) guiada por Pedro Gadelha y es miembro del grupo de música de cámara PianoSofia junto con Cristian Budu. Ganadora de numerosos concursos y solista en una ocasión con la Orquesta Filarmónica de Jena (Alemania). Actualmente reside en Brasil y estudia el Bachelor en Contrabajo en la Universidad Estadual Paulista (UNESP).',
        pt: 'Boliviana, además de ser parte del equipo de la Sociedad Boliviana de Música de Cámara, desde 2018 é Academista da Orquestra Sinfônica do Estado de São Paulo (Osesp) guiada por Pedro Gadelha e é membra do grupo de música de câmara PianoSofia junto ao pianista Cristian Budu. Ganhadora de inúmeros concursos e solista com a Orquestra Filarmônica de Jena, na Alemanha. Atualmente reside no Brasil e estuda seu bacharelado em contrabaixo na  Universidade Estadual Paulista (UNESP).'},
    'fernandobio': {
        en: 'Born in Sucre, former Ciudad de la Plata, where he began his musical and piano studies with Jorge García Marín. He studied at San Ignacio de Moxos, and cultivated his love for the composer J.S Bach with Elizabeth Schwimmer in Cochabamba. He is currently studying with Dr. Michael Baron at Bower School of Music - Florida Gulf Coast University, where he is a recipient of the "Nisita Fund for Music Excellence" scholarship. He is a member of the Bolivian Chamber Music Society, founder of the PianoeMusica Festival, co-founder of the Bolivian pianists’ association Con Fuoco.',
        es: 'Nacido en Sucre, antigua ciudad de La Plata, donde inició estudios musicales y de Piano con Jorge García Marin. Estudió en San Ignacio de Moxos, y aprendió a amar a Bach con Elizabeth Schwimmer en Cochabamba. Actualmente estudia con Dr. Michael Baron y es beneficiario de la beca “Charles Lussenhop and Kayetta Slocum Scholarship” y “Nisita Fund for Music Excellence” en Bower School of Music, Florida Gulf Coast University. Es miembro de La Sociedad Boliviana de Música de Cámara, fundador del Festival PianoeMusica y co-fundador de la asociación de pianistas bolivianos Con Fuoco.',
        pt: 'Nascido em Sucre, antiga cidade de La Plata, onde iniciou seus estudos musicais e de piano com Jorge García Marin. Estudou em San Ignacio de Moxos, e aprendeu a amar a Bach com Elizabeth Schwimmer em Cochabamba. Atualmente estuda com o Dr. Michael Baron e é beneficiário da bolsa "Chalres Lussenhop and Kayetta Slocum Scholarship” e “Nisita Fund for Music Excellence” na Bower School of Music, Florida Gulf Coast University. É membro da Sociedade Boliviana de Música de Câmara, fundador do Festival PianoeMusica e co-fundador da associação de pianistas bolivianos Con Fuoco.'},
    'gabrielbio': {
        en: 'Better known as Gabo, he was born in La Paz. He began his musical studies in Tarija with Franz Condori. He later continued in the Municipal School of Arts of El Alto with Fredy Céspedes and in the Musical Training Program "Bolivia Clásica" with Armando Vera and Alexander Lapich. He is currently part of the Clova Quartet and studies under the tutelage of violinist Jack Liebeck at the Royal Academy of Music in London.',
        es: 'Mejor conocido como Gabo, nació en La Paz. Empezó sus estudios musicales en Tarija con Franz Condori. Más tarde continuó en la Escuela Municipal de las Artes de El Alto con Fredy Céspedes y en el Programa de Formación Musical "Bolivia Clásica" con Armando Vera y Alexander Lapich. Actualmente es parte del Cuarteto Clova y estudia en la Real Academia de Música de Londres, bajo la tutela del violinista Jack Liebeck.',
        pt: 'Melhor conhecido como Gabo, nasceu em La Paz. Começou seus estudos musicais em Tarija com Franz Condori. Mais tarde continuou na Escola  Municipal de Artes no El Alto com Fredy Céspedes e no Programa de Formação Musical "Bolivia Clássica" com Armando Vera e Alexander Lapich. Atualmente é parte do Quarteto Clova e estuda na Royal Academy of Música em Londres, sob tutela do violinista Jack Liebeck.'},
    'karinbio': {
        en: "Born in Santa Cruz de la Sierra, she is a violinist and researcher specialized in Historically Informed Interpretation, Karin has studied in the USA at Montclair State University, Case Western Reserve University and in London at the Royal Academy of Music. She was part of distinguished early music ensembles in Europe and North America such as the American Bach Soloists, Apollo's Fire, Oxford Bach Soloists, Les Boreades, L 'Harmonie des Saisons, among others. She currently lives in Montreal-Canada, where she is pursuing a PhD in Musicology at McGill University.",
        es: 'Nacida en Santa Cruz de la Sierra, violinista e investigadora especializada en Interpretación Históricamente Informada, Karin ha estudiado en EEUU en Montclair State University, Case Western Reserve University y en Londres en la Royal Academy of Music. Formó parte de distinguidos ensambles de música antigua en Europa y Norteamérica tales como American Bach Soloists, Apollo’s Fire, Oxford Bach Soloists, Les Boreades,L’Harmonie des Saisons, entre otros. Actualmente reside en Montreal Canadá donde cursa el PhD en Musicología en la Universidad McGill.',
        pt: 'Nascida em Santa Cruz de la Sierra, violinista e investigadora especializada em Interpretação Historicamente Informada, Karin estudou na Montclair State University, Case Western Reserve University nos Estados Unidos e na Royal Academy of Music em Londres. Formou parte de notáveis grupos de música antiga na na Europa e na América do Norte como American Bach Soloists, Apollo’s Fire, Oxford Bach Soloists, Les Boreades, L’Harmonie des Saisons, entre outros. Atualmente reside em Montreal onde cursa o PhD em Musicologia na Universidade McGill.'
    },
    'karlabio': {
        en: 'Born in Cochabamba, she began her Music studies at the Instituto Eduardo Laredo and attended numerous Workshops, Festivals and Masterclasses in Bolivia and abroad. She was part of many groups such as the Cochabamba Philharmonic Orchestra, DJazz Big Band and Cochabamba Wind Symphony. She was a soloist on several occasions with the Youth Symphony Orchestra at the Jornadas de Música Contemporanea festival. Karla premiered works such as: "Fantasy for clarinet and piano" by David Valdivieso and recently "Three inventions on Bolivian airs" by Nicolás Suarez. She is specialized in Soprano Clarinet and Piccolo Clarinet and she is also trained in Clarinet maintenance and restoration. She currently resides in Cochabamba and works as a clarinet teacher at Instituto Eduardo Laredo.',
        es: 'Nacida en Cochabamba, inició sus estudios de Música en el Instituto Eduardo Laredo y cursó numerosos Talleres, Festivales y Masterclasses en Bolivia y en el extranjero. Formó parte de muchas agrupaciones como la Orquesta Filarmónica de Cochabamba, DJazz Big band y Cochabamba Wind Symphony y fué solista en varias ocasiones con la Orquesta Sinfónica Juvenil de las Jornadas de Música contemporánea. Realizó el estreno de obras como: “Fantasía para clarinete y piano” de David Valdivieso y recientemente “Tres invenciones sobre aires bolivianos” de Nicolás Suarez. Es especialista en Clarinete Soprano y Clarinete Píccolo y también es conocida por realizar mantenimiento y restauración de Clarinetes. Actualmente reside en Cochabamba y se desempeña como docente de Clarinete en el Instituto Eduardo Laredo.',
        pt: 'Nascida em Cochabamba, iniciou seus estudos de música no Instituto Eduardo Laredo e cursou numerosas oficinas, festivais e masterclasses na Bolivia e nos exterior. Formou parte de diversos ensembles como a Orquesta Filarmónica de Cochabamba, DJazz Big band e Cochabamba Wind Symphony. Foi solista em várias ocasiões com a Orquestra Sinfônica Juvenil e das Jornadas de Música Contemporânea. Realizou a estréia das obras Fantasia para Clarinete e Piano de David Valdivieso e recentemente Três Invenciones sobre Aires Bolivianos de Nicolás Suarez. É especialista em clarinete soprano, clarinete piccolo e reconhecida por seus trabalhos de restauro de clarinetes. Atualmente reside em Cochabamba e se desempenha como docente de clarinete no Instituto Eduardo Laredo.'},
    'paolabio': {
        en: 'Born in Cochabamba, 24 years old. Paola is part of the Bolivian Chamber Music Society and  member of the Orquestra Jovem do Estado de São Paulo. She is also part of the PIAP percussion ensemble and studies a Bachelor of Percussion at the Paulista State University UNESP. She participated in many international festivals, including: Campos do Jordão Festival (Brazil), Music Festival nas Montanhas (Brazil) and as section chair at the Britten-Pears Orchestra (United Kingdom). Paola won the first prize at the Ernani de Almeida Machado Award 2020, obtaining a scholarship of R $ 100,000 reais (US $ 20,000) to continue her studies abroad.',
        es: 'Cochabambina de 24 años, Paola es parte del equipo de La Sociedad Boliviana de Música de Cámara, y miembro de la Orquestra Jovem do Estado de São Paulo. Además, forma parte del grupo de percusión PIAP y estudia Bachelor en Percusión en la Universidad Estadual Paulista UNESP. Participó en muchos festivales internacionales, entre ellos: Festival Campos de Jordão (Brasil), Festival Música nas Montanhas (Brasil) y como jefe de sección Britten-Pears Orchestra (Reino Unido). Paola ganó el primer Premio Ernani de Almeida Machado 2020, obteniendo el primer premio una beca de R$100 mil reales (U$20 mil dólares) para perfeccionar sus estudios en el exterior.',
        pt: 'Cochabambina, Paola é parte da equipe da Sociedad Boliviana de Música de Câmara e integrante da Orquestra Jovem do Estado de São Paulo. Também é parte do grupo de percussão PIAP e está terminando seu bacharelado em percussão na Universidade Estadual Paulista UNESP. Participou de inumeros festivais internacionais como Britten-Pears Orchestra na Inglaterra, Festival de Inverno de Campos do Jordão e Música nas Montanhas no Brasil. Vencedora do Prêmio Ernani de Almeida Machado 2020, Paola recebeu o primeiro prêmio de  R$100 mil reais (U$20 mil dólares) para aperfeiçoar seus estudos no exterior.'},

    // Bio quotes
    'camilaquote': {
        en: "Música para Respirar 24/7 has changed my musical life. Making music in such an intimate setting it's a completely different experience than that of the concert halls. We have found a way of using technology as a powerful yet human tool for democratizing access to music",
        es: 'Música para Respirar 24/7 ha cambiado mi vida musical. Hacer música de una forma tan íntima es una experiencia muy diferente a la que vivimos en las salas de conciertos. Con este proyecto descubrimos una forma de utilizar la tecnología como una herramienta de democratización al arte muy potente y, al mismo tiempo, muy humana',
        pt: 'Música para Respirar 24/7 tem mudado a minha vida musical. Fazer música de uma forma tão íntima é uma experiência muito diferente da qual vivemos nas salas de concertos. Com este projeto descobrimos uma forma de utilizar a tecnologia como uma ferramenta de democratização da arte muito potente e, ao mesmo tempo, muito humana'
    },
    'brunoquote': {
        en: 'Being available 24 hours a day to perform personal concerts for those in need is a great empathy exercise. Music to Breathe 24/7 is our way of using the power of music and human connections, in real time, for healing. during the worst health crisis of our lives',
        es: 'Estar disponible 24 horas por día para tocar conciertos personales para los que necesitan es un enorme ejercicio de empatía. Música para Respirar 24/7 es nuestra manera de utilizar el poder de la música y de las conexiones humanas, en tiempo real, para sanación durante la peor crisis sanitaria de nuestras vidas',
        pt: 'Estar disponível 24 horas por dia para tocar concertos pessoais para quem necessita é um enorme exercício de empatia. Música para Respirar 24/7 é a nossa maneira de utilizar o poder da música e das conexões humanas, em tempo real, para sanação durante a pior crise sanitária das nossas vidas'
    },
    'vaniaquote': {
        en: 'To give is also to receive. Sometimes we are so caught up in our own needs that we forget that giving our talent and our love is what also fulfills us. If you can get a smile, evoke memories or at least distract someone who needs it in dark moments, we are fulfilling our mission as musicians and human beings',
        es: 'Dando también se recibe. A veces estamos tan enfrascados en nuestras propias necesidades, que nos olvidamos que el dar, el dar nuestro talento y nuestro amor es aquello que también nos llena. Si se puede sacar una sonrisa, evocar remembranzas o por lo menos distraer a ese alguien que lo necesita en momentos oscuros, estamos cumpliendo nuestra misión como músicos y seres humanos',
        pt: 'Dando também se recebe. Às vezes estamos tão enfrascados nas nossas próprias necessidades, que nos esquecemos que ao dar nosso talento e nosso amor é aquilo que também nos completa. Se você pode tirar um sorriso, evocar lembranças ou pelo menos distrair a este alguém que necessita em momentos escuros, estamos cumprindo nossa missão como músicos e seres humanos'
    },
    'anahiquote': {
        en: 'This project will allow me to contribute at least a little to the well-being of people. Music to Breathe 24/7 is an opportunity for musicians to reconnect with people and share our art with them',
        es: 'Este proyecto me permitirá contribuir al menos un poco en el bienestar de las personas. Música para respirar 24/7  es una oportunidad para que los músicos podamos volver a conectarnos con la gente y compartamos nuestro arte con ellos',
        pt: 'Este projeto me permitirá contribuir ao menos um pouco para o bem estar das pessoas. Música para Respirar 24/7 é uma oportunidade para que os músicos podemos voltar a nos conectar com as pessoas e compartilhar nossa arte com elas'
    },
    'claudiaquote': {
        en: 'I want to participate because I want to give love, air, peace, and travel through music, in these uncertain times, but full of hope',
        es: 'Quiero participar porque quiero dar amor, aire, paz, y viajar a través de la música, en estos tiempos inciertos, pero llenos de esperanza',
        pt: 'Quero participar porque quero dar amor, ar, paz e viajar através da música, nestes tempos incertos, mas cheios de esperança'
    },
    'fernandoquote': {
        en: 'Never before we depend so much on so little; a few minutes may be enough to save our lives; we want to offer a few moments of music to all who can find light and hope in it',
        es: 'Nunca antes como ahora, dependemos tanto de tan poco; unos minutos pueden ser suficientes para salvarnos la vida; queremos ofrecer unos instantes de música a todos los que en ella puedan encontrar luz y esperanza.',
        pt: 'Nunca antes dependemos tanto de tão pouco; uns minutos podem ser suficientes para salvarmos a vida; queremos oferecer uns instantes de música a todos os que nela possam encontrar luz e esperança'
    },
    'gabrielquote': {
        en: 'During the pandemic, the physiological effects of the virus are strong, so are the psychological ones, not only for doctors, patients and relatives, but also for the general public who live in constant fear. Having the opportunity to reach out to people to bring peace, albeit for a short period of time, adds a noble purpose to what we do',
        es: 'Durante la pandemia, si bien los efectos fisiológicos del virus son fuertes, también lo son los psicológicos, no sólo para los doctores, pacientes y allegados, también para el público en general que vive en constante miedo. Tener la oportunidad de llegar a las personas para brindar paz, aunque por un corto período de tiempo, añade un propósito noble a lo que hacemos',
        pt: 'Durante la pandemia, se os efeitos fisiológicos do vírus são fortes, também são os psicológicos, não só para os doutores, pacientes e familiares, também para o público geral que vive em constante medo. Ter a oportunidade de chegar às pessoas para brindar paz, mesmo que por um período curto de tempo, adiciona um propósito nobre ao que fazemos'
    },
    'karinquote': {
        en: 'I am very happy to be part of‘ La Society and participate in the activity Music to breathe 24/7, I think that giving music to people affected by this pandemic can make the mind travel, elevate the spirit and heal the body',
        es: 'Estoy muy emocionada de formar parte del proyecto Música que Sana y Música para Respirar 24/7, porque creo en el poder sanador de la música y quiero ofrecer mi arte a la población Boliviana tan afectada por Covid-19',
        pt: 'Estou muito emocionada de formar parte do projeto Música para Respirar 24/7, porque creio no poder de sanação da música e quero oferecer minha arte para a população Boliviana tão afetada pelo COVID-19'
    },
    'karlaquote': {
        en: 'I am very excited to be part of the Music to Breathe 24/7 project, because I believe in the healing power of music and I want to offer my art to the Bolivian population so affected by Covid-19',
        es: 'Estoy muy feliz de ser parte de ‘La Sociedad’ y participar en la actividad Música para respirar 24/7, pienso que regalar música a la gente afectada en esta pandemia puede hacer viajar a la mente, elevar el espíritu y sanar el cuerpo',
        pt: 'Estou muito feliz em ser parte da La Sociedad e participar de Música para Respirar 24/7, penso que presentear música às pessoas afetadas nesta pandemia pode fazer a mente viajar, elevar seus espíritos e sanar o corpo'
    },
    'paolaquote': {
        en: 'I want to participate because I want to travel with the listeners to a space of Peace, Love and Union',
        es: 'Quiero participar porque quiero viajar con los oyentes a un espacio de Paz, Amor y Unión',
        pt: 'Quero participar porque quero viajar com os ouvintes a um espaço de Paz, Amor e União'
    },
}
