export default [
    {fullName: 'Alejandro Aizcorbe', firstName: 'Alejandro', lastName: 'Aizcorbe', instrument: 'clarinet', origen: 'uruguay'},
    {fullName: 'Alejandro Aldana', firstName: 'Alejandro', lastName: 'Aldana', instrument: 'violin', origen: 'argentina'},
    {fullName: 'Daniel Alvarez Veizaga', firstName: 'Daniel', lastName: 'Alvarez Veizaga', instrument: 'piano', origen: 'bolivia'},
    {fullName: 'Mauricio Aramayo', firstName: 'Mauricio', lastName: 'Aramayo', instrument: 'violin', origen: 'bolivia'},
    {fullName: 'Erique Arce', firstName: 'Erique', lastName: 'Arce', instrument: 'percusion', origen: 'uruguay'},
    {fullName: 'Karen Arce', firstName: 'Karen', lastName: 'Arce', instrument: 'charango', origen: 'bolivia'},
    {fullName: 'Reinaldo Arce', firstName: 'Reinaldo', lastName: 'Arce', instrument: 'percusion', origen: 'ecuador'},
    {fullName: 'Francisca Ardiles', firstName: 'Francisca', lastName: 'Ardiles', instrument: 'clarinet', origen: 'chile'},
    {fullName: 'Soledad Aya', firstName: 'Soledad', lastName: 'Aya', instrument: 'harp', origen: 'argentina'},
    {fullName: 'Camila Barrientos', firstName: 'Camila', lastName: 'Barrientos', instrument: 'clarinet', origen: 'bolivia'},
    {fullName: 'Rodrigo Becerra', firstName: 'Rodrigo', lastName: 'Becerra', instrument: 'bass', origen: 'chile'},
    {fullName: 'Tomás Bernal', firstName: 'Tomás', lastName: 'Bernal', instrument: 'percusion', origen: 'ecuador'},
    {fullName: 'Olga Bertinat', firstName: 'Olga', lastName: 'Bertinat', instrument: 'flute', origen: 'uruguay'},
    {fullName: 'Gabriel Bilbao', firstName: 'Gabriel', lastName: 'Bilbao', instrument: 'violin', origen: 'bolivia'},
    {fullName: 'Gloria Britez Scolari', firstName: 'Gloria', lastName: 'Britez Scolari', instrument: 'viola', origen: 'paraguay'},
    {fullName: 'Carlos Cabrera Gonzáles', firstName: 'Carlos', lastName: 'Cabrera Gonzáles', instrument: 'clarinet', origen: 'chile'},
    {fullName: 'Mauricio Canedo', firstName: 'Mauricio', lastName: 'Canedo', instrument: 'guitar', origen: 'bolivia'},
    {fullName: 'Facundo Cantero', firstName: 'Facundo', lastName: 'Cantero', instrument: 'bassoon', origen: 'argentina'},
    {fullName: 'Cassia Carrascosa', firstName: 'Cassia', lastName: 'Carrascosa', instrument: 'flute', origen: 'brazil'},
    {fullName: 'Daniel Cerron', firstName: 'Daniel', lastName: 'Cerron', instrument: 'voice', origen: 'mexico'},
    {fullName: 'Maritza Cisneros', firstName: 'Maritza', lastName: 'Cisneros', instrument: 'bass', origen: 'mexico'},
    {fullName: 'Carla Claros', firstName: 'Carla', lastName: 'Claros', instrument: 'flute', origen: 'bolivia'},
    {fullName: 'Willy Claure', firstName: 'Willy', lastName: 'Claure', instrument: 'singer songwriter', origen: 'bolivia'},
    {fullName: 'Mariela Condo', firstName: 'Mariela', lastName: 'Condo', instrument: 'voice', origen: 'ecuador'},
    {fullName: 'Mayra Contreras', firstName: 'Mayra', lastName: 'Contreras', instrument: 'soprano', origen: 'chile'},
    {fullName: 'Luz Cortés', firstName: 'Luz', lastName: 'Cortés', instrument: 'oboe', origen: 'mexico'},
    {fullName: 'Karin Cuellar', firstName: 'Karin', lastName: 'Cuellar', instrument: 'baroque violin', origen: 'bolivia'},
    {fullName: 'Arpad Debreczeni', firstName: 'Arpad', lastName: 'Debreczeni', instrument: 'violin', origen: 'bolivia'},
    {fullName: 'Alina Delgadillo Espinoza', firstName: 'Alina', lastName: 'Delgadillo Espinoza', instrument: 'voice', origen: 'bolivia'},
    {fullName: 'Federico Diaz', firstName: 'Federico', lastName: 'Diaz', instrument: 'guitar', origen: 'argentina'},
    {fullName: 'Víctor Diaz', firstName: 'Víctor', lastName: 'Diaz', instrument: 'bass', origen: 'bolivia'},
    {fullName: 'Carla Dipp', firstName: 'Carla', lastName: 'Dipp', instrument: 'oboe', origen: 'bolivia'},
    {fullName: 'Argentina Duran', firstName: 'Argentina', lastName: 'Duran', instrument: 'piano', origen: 'mexico'},
    {fullName: 'Zsolt Eder', firstName: 'Zsolt', lastName: 'Eder', instrument: 'violin', origen: 'hungary '},
    {fullName: 'Javier Escalera', firstName: 'Javier', lastName: 'Escalera', instrument: 'violin', origen: 'bolivia'},
    {fullName: 'Sergio Escalera', firstName: 'Sergio', lastName: 'Escalera', instrument: 'piano', origen: 'bolivia'},
    {fullName: 'Amado Espinoza', firstName: 'Amado', lastName: 'Espinoza', instrument: 'multi-instrumentalist', origen: 'bolivia'},
    {fullName: 'Nelton Essi', firstName: 'Nelton', lastName: 'Essi', instrument: 'percusion', origen: 'brazil'},
    {fullName: 'Gabriel Fabiani', firstName: 'Gabriel', lastName: 'Fabiani', instrument: 'guitar', origen: 'bolivia'},
    {fullName: 'Pedro Fernandez', firstName: 'Pedro', lastName: 'Fernandez', instrument: 'percusion', origen: 'panama'},
    {fullName: 'Paola Fuentes', firstName: 'Paola', lastName: 'Fuentes', instrument: 'violin', origen: 'chile '},
    {fullName: 'Tadeo Gangontena', firstName: 'Tadeo', lastName: 'Gangontena', instrument: 'piano', origen: 'ecuador'},
    {fullName: 'Simón Gangotena', firstName: 'Simón', lastName: 'Gangotena', instrument: 'viola', origen: 'ecuador'},
    {fullName: 'Andrea Garcia', firstName: 'Andrea', lastName: 'Garcia', instrument: 'cello', origen: 'bolivia'},
    {fullName: 'Anahí Garvizu', firstName: 'Anahí', lastName: 'Garvizu', instrument: 'cello', origen: 'bolivia'},
    {fullName: 'Esteban Gavilanes', firstName: 'Esteban', lastName: 'Gavilanes', instrument: 'piano', origen: 'ecuador'},
    {fullName: 'Nicolás Giordano', firstName: 'Nicolás', lastName: 'Giordano', instrument: 'violin', origen: 'argentina'},
    {fullName: 'Maria Fernanda Godoy', firstName: 'Maria Fernanda', lastName: 'Godoy', instrument: 'viola', origen: 'chile'},
    {fullName: 'Kelly Hall-Thompkins', firstName: 'Kelly', lastName: 'Hall-Thompkins', instrument: 'violin', origen: 'united states of america'},
    {fullName: 'Adriana Inturias', firstName: 'Adriana', lastName: 'Inturias', instrument: 'piano', origen: 'bolivia'},
    {fullName: 'Karla Jiménez', firstName: 'Karla', lastName: 'Jiménez', instrument: 'clarinet', origen: 'bolivia'},
    {fullName: 'Teresa Laredo', firstName: 'Teresa', lastName: 'Laredo', instrument: 'music therapist', origen: 'bolivia'},
    {fullName: 'Oscar Latorre', firstName: 'Oscar', lastName: 'Latorre', instrument: 'guitar', origen: 'chile'},
    {fullName: 'Gabriele Leite', firstName: 'Gabriele', lastName: 'Leite', instrument: 'guitar', origen: 'brazil'},
    {fullName: 'Cassia Lima', firstName: 'Cassia', lastName: 'Lima', instrument: 'flute', origen: 'brazil'},
    {fullName: 'Gabriel Lima', firstName: 'Gabriel', lastName: 'Lima', instrument: 'viola', origen: 'brazil'},
    {fullName: 'Erick López', firstName: 'Erick', lastName: 'López', instrument: 'saxophone', origen: 'mexico'},
    {fullName: 'Fernando López', firstName: 'Fernando', lastName: 'López', instrument: 'piano', origen: 'bolivia'},
    {fullName: 'Bruno Lourensetto', firstName: 'Bruno', lastName: 'Lourensetto', instrument: 'trumpet', origen: 'brazil'},
    {fullName: 'Claudia Machicado', firstName: 'Claudia', lastName: 'Machicado', instrument: 'bass', origen: 'bolivia'},
    {fullName: 'Paola Machicado', firstName: 'Paola', lastName: 'Machicado', instrument: 'percusion', origen: 'bolivia'},
    {fullName: 'Roberta Marcinkowski', firstName: 'Roberta', lastName: 'Marcinkowski', instrument: 'viola', origen: 'brazil'},
    {fullName: 'Guillermo Marin', firstName: 'Guillermo', lastName: 'Marin', instrument: 'clarinet', origen: 'colombia'},
    {fullName: 'Patricia Martinez', firstName: 'Patricia', lastName: 'Martinez', instrument: 'percusion', origen: 'ecuador'},
    {fullName: 'Amanda Martins', firstName: 'Amanda', lastName: 'Martins', instrument: 'violin', origen: 'brazil'},
    {fullName: 'Clementina Moreira', firstName: 'Clementina', lastName: 'Moreira', instrument: 'voice', origen: 'uruguay'},
    {fullName: 'Daniela Moya', firstName: 'Daniela', lastName: 'Moya', instrument: 'flute', origen: 'bolivia'},
    {fullName: 'Alondra Nina', firstName: 'Alondra', lastName: 'Nina', instrument: 'clarinet', origen: 'bolivia'},
    {fullName: 'Sergio Joaquín Olivares Ayala', firstName: 'Sergio Joaquín', lastName: 'Olivares Ayala', instrument: 'saxophone', origen: 'chile '},
    {fullName: 'Gustavo Orihuela', firstName: 'Gustavo', lastName: 'Orihuela', instrument: 'violin', origen: 'bolivia'},
    {fullName: 'Zoraida Oyola Rebaza', firstName: 'Zoraida', lastName: 'Oyola Rebaza', instrument: 'cello', origen: 'peru'},
    {fullName: 'Varinia Oyola-Rebaza', firstName: 'Varinia', lastName: 'Oyola-Rebaza', instrument: 'viola', origen: 'peru'},
    {fullName: 'Felipe Painean', firstName: 'Felipe', lastName: 'Painean', instrument: 'trumpet', origen: 'chile'},
    {fullName: 'Helder Passinho', firstName: 'Helder', lastName: 'Passinho', instrument: 'trumpet', origen: 'brazil'},
    {fullName: 'Luca Raele', firstName: 'Luca', lastName: 'Raele', instrument: 'clarinet', origen: 'brazil'},
    {fullName: 'Elena Rey Rodriguez', firstName: 'Elena', lastName: 'Rey Rodriguez', instrument: 'violin', origen: 'spain'},
    {fullName: 'Sergio Reyes', firstName: 'Sergio', lastName: 'Reyes', instrument: 'violin', origen: 'guatemala'},
    {fullName: 'Rosangela Rhafaelle', firstName: 'Rosangela', lastName: 'Rhafaelle', instrument: 'percusion', origen: 'brazil'},
    {fullName: 'Blanca Rodríguez Torres', firstName: 'Blanca', lastName: 'Rodríguez Torres', instrument: 'voice', origen: 'mexico'},
    {fullName: 'Gabriela Ruiz', firstName: 'Gabriela', lastName: 'Ruiz', instrument: 'piano', origen: 'bolivia'},
    {fullName: 'Miguel Salazar Hidalgo', firstName: 'Miguel', lastName: 'Salazar Hidalgo', instrument: 'violin', origen: 'bolivia'},
    {fullName: 'Bianca Santos', firstName: 'Bianca', lastName: 'Santos', instrument: 'trumpet', origen: 'brazil'},
    {fullName: 'Alfonso Silva Ramírez', firstName: 'Alfonso', lastName: 'Silva Ramírez', instrument: 'sonotherapist', origen: 'chile '},
    {fullName: 'Gabriel Takano', firstName: 'Gabriel', lastName: 'Takano', instrument: 'bass', origen: 'brazil'},
    {fullName: 'Gervasio Tarragona', firstName: 'Gervasio', lastName: 'Tarragona', instrument: 'clarinet', origen: 'uruguay'},
    {fullName: 'Emilio Teubal', firstName: 'Emilio', lastName: 'Teubal', instrument: 'piano', origen: 'argentina'},
    {fullName: 'Jaime Torres', firstName: 'Jaime', lastName: 'Torres', instrument: 'voice', origen: 'mexico'},
    {fullName: 'Sara Vaca', firstName: 'Sara', lastName: 'Vaca', instrument: 'cello', origen: 'bolivia'},
    {fullName: 'Guillermo Vaisman', firstName: 'Guillermo', lastName: 'Vaisman', instrument: 'acordeon', origen: 'argentina'},
    {fullName: 'Sandra Valenzuela', firstName: 'Sandra', lastName: 'Valenzuela', instrument: 'percusion', origen: 'ecuador'},
    {fullName: 'Leonel Vásquez Zúñiga', firstName: 'Leonel', lastName: 'Vásquez Zúñiga', instrument: 'cello', origen: 'chile '},
    {fullName: 'Eliana Véliz Cerda', firstName: 'Eliana', lastName: 'Véliz Cerda', instrument: 'violin', origen: 'chile'},
    {fullName: 'Mauricio Wayar', firstName: 'Mauricio', lastName: 'Wayar', instrument: 'bassoon', origen: 'bolivia'},
    ]
