export default {
    'descriptionP1': {
        en: 'Music to Breathe 24/7 is a project that aims to provide free concerts to all people who need a musical break in times of coronavirus. It is for this reason that the work carried out began voluntarily, but the larger the project became, the more effort, time and musicians it required to manage. ',
        es: 'Música para Respirar 24/7 es un proyecto que tiene la finalidad de brindar conciertos gratuitos a todas las personas que necesiten un respiro musical en tiempos de coronavirus. Es por esta razón que el trabajo realizado inició de manera voluntaria, pero mientras más grande se hizo el proyecto, su gestión requiere de más esfuerzo, tiempo y músicos.',
        pt: 'Music to Breathe 24/7 é um projeto que visa proporcionar shows gratuitos a todas as pessoas que precisam de um descanso musical em tempos de coronavírus. É por esta razão que o trabalho realizado começou voluntariamente, mas quanto maior se tornava o projeto, mais esforço, tempo e músicos exigia a sua gestão. '
    },
    'descriptionP2': {
        en: 'The cultural sector is one of the professional groups most affected by the pandemic, and their activities have not yet been reactivated at all. So, music has proven to be an indispensable tool for people and people like this to act directly to generate a social good. It has closed, Music to Breathe has managed to be sustainable thanks to the contributions of the German foundation that, in spite of the fact that the Music Academy of the West is called, in addition to the donations of several people who wanted to contribute through our new page of Gofundme. ',
        es: 'El sector cultural es uno de los grupos profesionales más afectados por la pandemia, ya que sus actividades aun no se han reactivado del todo. Aun así, la música ha demostrado ser una herramienta indispensable para el bienestar de las personas y proyectos como este actúan de manera directa apara generar un bien social. Hasta la fecha, Música para Respirar ha logrado ser sostenible gracias a las contribuciones de la fundación alemana que no se que se llama y Music Academy of the West, además de las donaciones de varias personas que quisieron aportar a través de nuestra página de Gofundme.',
        pt: 'O setor cultural é um dos grupos profissionais más afetados por la pandemia, ya que sus actividades aun no se han reactivado del todo. Aun así, a música ha demostrado ser una herramienta indispensável para a bienestar de las personas y projetos como este actúan de manera directa apara generar un bien social. Hasta la fecha, Música para Respirar ha logrado ser sostenible gracias a las contribuciones de la fundación alemana que no se que se llama y Academia de Música do Ocidente, además de las donaciones de varias personas que quisieron aportar a través de nuestra página de Gama. '
    },
    'descriptionP3': {
        en: 'We want to be able to recognize the work of all the musicians who participate as part of this beautiful project, and in turn, continue to offer our concerts free of charge, so that you can get to all the rincones wherever a bit of music is needed. We invite you to contribute with our project and be part of the joy we bring to people with our concerts. ',
        es: 'Queremos ser capaces de reconocer el trabajo de todos los músicos que participen en o formen parte de este hermoso proyecto, y a la vez, continuar ofreciendo nuestros conciertos de forma gratuita, para que lleguen a todos los rincones donde se necesite un poco de música. Te invitamos a contribuir con nuestro proyecto y ser parte de la alegría que llevamos a las personas con nuestros conciertos.',
        pt: 'Queremos ser capaces de reconocer el trabajo de todos os músicos que participen en o formen part of this hermoso proyecto, y a la vez, continuar ofreciendo nuestros conciertos de forma gratuita, para que lleguen a todos los rincones donde se necesite un poco de música. Te invitamos a contribuir com nuestro projeto y ser parte de la alegría que llevamos a las personas con nuestros conciertos. '
    },
}
